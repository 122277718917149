export const blankAddr = '0x0000000000000000000000000000000000000000';
export const testAddr = '';
export const V2Url = "https://bsc.fivexw.com/#/"
export const serverLink = 'https://bscservice.5swap.io';
export const FomoActionAddress='0x681A0d36561eb508C7f3D31F295D7891a4405f77';

// prod
export const shareAddress = '0x7fB089474f6D165a847A52cE042294911DC8c1bE';
export const contractAddr = [
    "0xD889194Cee50175a387CbAF1617B1F8A14b7f42b",
    "0x466E8D4536717f46B982eA7950A6c07f75753143",
  ];
export const scoreContractAddr = '0xF4233c1c1f065d8eA044B924966bc18A174A8CfA';
export const actionContractAddr = '0x681A0d36561eb508C7f3D31F295D7891a4405f77';
export const rewardBagAddress = '0xB47B9db5Fe5dfaE5242b4E382F3b748d687BD017';
export const scoreDecimal = 18;
export const tokenOptions = [
    {
        name: 'AURA',
        decimal: 18,
        address: '0xADB1Ab0912C8ead567fC40753aa7062ea897c745',
        unitDecimal: 0,
        unitName: ''
    },
    {
        name: 'USDT',
        decimal: 18,
        address: '0x55d398326f99059fF775485246999027B3197955',
        unitDecimal: 0,
        unitName: ''
    }
];
