import Btn1Img from '../images/market_btn1.png';
import Btn2Img from '../images/market_btn2.png';
import Btn3Img from '../images/market_btn3.png';
import BgImg from '../images/bg.png';
import BackImg from '../images/back.png';
import { FormattedMessage } from 'react-intl';

const marketStyle = {
    height: '100vh',
    background: `url('${BgImg}') 100% / cover`,
    marginBottom: '25vh'
};
const headerStyle = {
    height: '54px',
    lineHeight: '54px',
    background: '#fff',
    width: '100%',
    marginBottom: '20vh',
    textAlign: 'center'
};
const backStyle = {
    background: `url('${BackImg}') no-repeat 100% / contain`,
    height: '18px',
    width: '11px',
    position: 'absolute',
    left: '4%',
    top: '16px'
};
const imgStyle = {
    height: '64px',
    width: '211px',
    margin: '0px auto 28px auto',
    display: 'block'
};
const Market = (props) => {
    console.log(props);
    return (
        <div style={marketStyle}>
            <div style={headerStyle}>
                <div
                    onClick={() => {
                        props.history.go(-1);
                    }}
                    style={backStyle}></div>
                <FormattedMessage id='market' />
            </div>
            <img
                onClick={() => {
                    window.location.href = 'https://ht.mdex.co/';
                }}
                style={imgStyle}
                src={Btn1Img}
                alt='aura'
            />
            <img
                onClick={() => {
                    window.location.href =
                        'https://htmoon.finance/?utm_source=tokenpocket#/swap';
                }}
                style={imgStyle}
                src={Btn2Img}
                alt='htmoon'
            />
            <img
                onClick={() => {
                    window.location.href =
                        'https://htmoon.world/swap/?utm_source=tokenpocket#/';
                }}
                style={imgStyle}
                src={Btn3Img}
                alt='htham'
            />
        </div>
    );
};

export default Market;
