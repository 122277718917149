import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import style from '../style/lingdandetails.module.scss';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { serverLink } from '../store/constants';
import { message } from 'antd';
import { web3, removeDecimal } from '../utils';

const getLingdanInfo = async (account, lang) => {
    let errMsg = lang === 'zh' ? '网络错误' : 'Network errors';
    try {
        let TotalPoint = 0;
        await fetch(`${serverLink}/api/player-info/${account}`)
            .then(async (res) => res.json())
            .then((data) => {
                if (data.code === 0) {
                    TotalPoint = data.data?.TotalPoint;
                } else if (data.code === 1) {
                    message.error(errMsg);
                }
            });
        return TotalPoint;
    } catch (e) {
        console.log(e);
        message.error(errMsg);
        return 0;
    }
};

const getRecord = async (startTime, endTime, account, lang) => {
    let errMsg = lang === 'zh' ? '网络错误' : 'Network errors';
    let records = [];
    startTime /= 1000;
    endTime /= 1000;
    try {
        await fetch(
            `${serverLink}/api/player-point-logs/${account}?page=&start_time=${startTime}&end_time=${endTime}`
        )
            .then(async (res) => res.json())
            .then((data) => {
                if (data.code === 0) {
                    console.log(data);
                    records = data.data;
                } else if (data.code === 1) {
                    message.error(errMsg);
                }
            });
        return records;
    } catch (e) {
        console.log(e);
        message.error(errMsg);
        return 0;
    }
};

const LingDanDetails = (props) => {
    const lang = useSelector((state) => state.lang);
    const [totalNum, setTotalNum] = useState(0);
    const [records, setRecords] = useState([]);
    const [account, setAccount] = useState('');
    const handleChangeMonth = async (moment) => {
        const year = moment.year().toString();
        const month = (moment.month() + 1).toString().padStart(2, '0');
        const nextMonth = (moment.month() + 2).toString().padStart(2, '0');
        const startTime = new Date(
            `${year + '/' + month}/01 00:00:00`
        ).getTime();
        const endTime = new Date(
            `${year + '/' + nextMonth}/01 00:00:00`
        ).getTime();
        const records = await getRecord(startTime, endTime, account, lang);
        setRecords(records);
    };
    const currentYear = new Date().getFullYear().toString();
    const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, '0');
    useEffect(() => {
        const _init = async () => {
            const accounts = await web3().eth.getAccounts();
            setAccount(accounts[0]);
            const totalNum = removeDecimal(
                await getLingdanInfo(accounts[0], lang),
                0,
                1
            );
            setTotalNum(totalNum);
            const startTime = new Date(
                `${currentYear}/${currentMonth}/01 00:00:00`
            ).getTime();
            const endTime = new Date(
                `${
                    new Date().getFullYear() +
                    '/' +
                    (new Date().getMonth() + 2).toString().padStart(2, '0')
                }/01 00:00:00`
            ).getTime();
            const records = await getRecord(
                startTime,
                endTime,
                accounts[0],
                lang
            );
            setRecords(records);
        };
        _init();
        // eslint-disable-ncext-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className={style.lingDanDetails}>
            <div className={style.header}>
                <div
                    onClick={() => {
                        props.history.go(-1);
                    }}
                    className={style.back}></div>
                <FormattedMessage id='lingDanDetails' />
            </div>
            <div className={style.topContainer}>
                <div className={style.totalNum}>
                    <span>
                        <FormattedMessage id='totalLingdan' />
                    </span>
                    <span className={style.number}>{totalNum}</span>
                </div>
                <DatePicker
                    size='small'
                    onChange={handleChangeMonth}
                    picker='month'
                    locale={locale}
                    inputReadOnly={true}
                    defaultValue={moment(
                        `${currentYear}-${currentMonth}-01T00:00:00`
                    )}
                    allowClear={false}
                    className={style.datePicker}
                />
            </div>
            <ul className={style.list}>
                {records.length
                    ? records.map((val) => (
                          <li key={val.ID}>
                              <div className={style.left}>
                                  <p>{val.Title}</p>
                                  <p className={style.date}>
                                      {moment(val.CreateTime * 1000).format(
                                          'YYYY-MM-DD, hh:mm:ss'
                                      )}
                                  </p>
                              </div>
                              <div className={style.right}>
                                  +{removeDecimal(val.Point, 0, 1)}
                              </div>
                          </li>
                      ))
                    : null}
            </ul>
        </div>
    );
};

export default LingDanDetails;
