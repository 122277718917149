import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import style from '../style/shengwuDetails.module.scss';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { serverLink, testAddr,V2Url } from '../store/constants';
import { message } from 'antd';
import { web3, removeDecimal } from '../utils';

const getLingdanInfo = async (account, lang) => {
    let errMsg = lang === 'zh' ? '网络错误' : 'Network errors';
    try {
        let TotalPoint = 0;
        await fetch(`${serverLink}/api/player-info/${account}`)
            .then(async (res) => res.json())
            .then((data) => {
                if (data.code === 0) {
                    TotalPoint = data.data?.TotalPoint;
                } else if (data.code === 1) {
                    message.error(errMsg);
                }
            });
        return TotalPoint;
    } catch (e) {
        console.log(e);
        message.error(errMsg);
        return 0;
    }
};

const getRecord = async (startTime, endTime, account, lang) => {
    let errMsg = lang === 'zh' ? '网络错误' : 'Network errors';
    let records = [];
    startTime /= 1000;
    endTime /= 1000;
    try {
        await fetch(
            `${serverLink}/api/player-point-logs/${account}?page=&start_time=${startTime}&end_time=${endTime}`
        )
            .then(async (res) => res.json())
            .then((data) => {
                if (data.code === 0) {
                    console.log(data);
                    records = data.data;
                } else if (data.code === 1) {
                    message.error(errMsg);
                }
            });
        return records;
    } catch (e) {
        console.log(e);
        message.error(errMsg);
        return 0;
    }
};
const getList = async (account) => {
    let list = [];

    try {
        await fetch(
            `${serverLink}/api/player/${account}/relic-assets`
        )
            .then(async (res) => res.json())
            .then((data) => {
                if (data.code === 0) {
                    console.log(data);
                    list = data.data;
                } else if (data.code === 1) {
                    message.error(data.msg);
                }
            });
        return list;
    } catch (e) {
        console.log(e);
        message.error(e.msg);
        return 0;
    }
};
const ShengWuDetails = (props) => {
    let [account, setAccount] = useState('');
    const lang = useSelector((state) => state.lang);
    const [totalNum, setTotalNum,] = useState(0);
    const [records, setRecords] = useState([]);
    const [list, setLists] = useState([]);
    const currentMonth =
        new Date().getFullYear().toString() +
        '-' +
        (new Date().getMonth() + 1).toString().padStart(2, '0');
    const onChange = async (moment, str) => {
        const year = moment.year().toString();
        const month = (moment.month() + 2).toString().padStart(2, '0');
        const startTime = new Date(`${str}-01 00:00:00`).getTime();
        const endTime = new Date(`${year + month}--01 00:00:00`).getTime();
        const records = await getRecord(startTime, endTime, account, lang);
        const list = await getList(account);
        setLists(list);
        setAccount(account);
        setRecords(records);
    };
    useEffect(() => {
        const _init = async () => {
            const accounts = await web3().eth.getAccounts();
            account = accounts[0];
            // account = testAddr;
            const totalNum = removeDecimal(
                await getLingdanInfo(account, lang),
                0,
                1
            );
            setTotalNum(totalNum);
            const startTime = new Date(`${currentMonth}-01 00:00:00`).getTime();
            const endTime = new Date(
                `${(new Date().getMonth() + 2)
                    .toString()
                    .padStart(2, '0')}-01 00:00:00`
            ).getTime();
            const records = await getRecord(startTime, endTime, account, lang);
            const list = await getList(account);
            setLists(list)
            setAccount(account);
            setRecords(records);
        };
        _init();
    }, []);
    return (
        <div className={style.lingDanDetails}>
            <ul className={style.list}>
                {list.length
                    ? list.map((val, index) => (
                        <li key={index} >
                            <a href={V2Url+`springInfo?tokenAddr=${account}&token=${val.token_name}&name=${val.relic_title}&type=3`}>
                            <div>
                            <img src={'icon/'+val.token_name+'.png' } />
                                <span className={style.token_name} >{val.relic_title}</span>
                            </div>
                            <div  className={style.pink}>{val.amount}<span  className={style.right}></span> </div>
                            </a>
                        </li>
                    ))
                    : null}
            </ul>
        </div>
    );
};

export default ShengWuDetails;
