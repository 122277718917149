import React from "react";
import style from "../style/table.module.scss";
import { blankAddr } from "../store/constants";
import { FormattedMessage } from "react-intl";
import winTwo from "../images/winTwo.png";
import * as utils from "../utils";

// ne: 游戏不存在; Blocking: 投注已完毕,等待下一个区块由外部触发开奖结算; Cancel: 没有凑满人，退币
const statusType = { Ready: 0, Playing: 1, Blocking: 2, End: 3, Cancel: 4 };
function getConterCon(status, hitBlockHash) {
  const hashArray = ["", "", ""];
  if (hitBlockHash !== blankAddr) {
    const hash = hitBlockHash.substring(hitBlockHash.length - 10);
    for (let i = hash.length - 1; i >= 0; i--) {
      const c = hash[i];
      if (!isNaN(c)) {
        hashArray[1] = c;
        hashArray[0] = hash.substring(0, i);
        break;
      } else {
        hashArray[2] += c;
      }
    }
  }
  let str = "";
  switch (status) {
    case statusType.Blocking:
      str = "draw";
      break;
    case statusType.End:
      str = "over";
      break;
    case statusType.Cancel:
      str = "cancelled";
      break;
    default:
      break;
  }
  return (
    <div
      onClick={() => {
        goToLink(status, hitBlockHash);
      }}
      className={`
            ${style.centerConWarp} ${
        status === statusType.Playing || status === statusType.Ready
          ? style.logoNone
          : ""
      }`}
    >
      <div className={style.centerCon}>
        {status === statusType.End ? (
          <p>
            {hashArray[0]}
            <span className={style.highlight}>{hashArray[1]}</span>
            {hashArray[2]}
          </p>
        ) : str ? (
          <FormattedMessage id={str} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

const goToLink = (status, hash) => {
  if (status === statusType.End) {
    //test
    //window.open(`https://testnet.hecoinfo.com/block/${hash}`);
    //prod
    window.open(`https://bscscan.com/block/${hash}`);
  }
};

function TableTwo(props) {
  const { data, isSmall, account, currentToken, fromHome } = props;
  const positions = ["yin", "yang"];
  const status = Number(data.status);
  return (
    <div>
      {!fromHome && (
        <div
          className={`${style.amount} ${
            status === statusType.End &&
            data.bettor[Number(data.hitBettor)] === account
              ? style.winner
              : ""
          }`}
        >
          {utils.formatValueOption(
            utils.removeDecimal(data.betValue, currentToken.decimal),
            currentToken.unitName,
            currentToken.unitDecimal
          )}
          {currentToken.name}
        </div>
      )}
      <div
        className={[style.tableTwo, isSmall ? style.smallTxt : ""].join(" ")}
      >
        {getConterCon(status, data.hitBlockHash)}
        {positions.map((pos, ix) => {
          return (
            <div
              key={ix}
              className={`${style[pos]} ${
                data.bettor[ix] !== blankAddr
                  ? data.bettor[ix] === account
                    ? style["myPickTwo" + pos]
                    : style["othersPickTwo" + pos]
                  : ""
              }`}
            >
              {status === statusType.End && Number(data.hitBettor) === ix && (
                <img className={style["winner" + pos]} alt="" src={winTwo} />
              )}
              {/* {status === statusType.End &&
                Number(data.hitBettor) === ix &&
                data.bettor[ix] !== account && (
                  <img className={style.winner} alt="" src={winTwo} />
                )} */}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TableTwo;
