import React, { Component } from "react";
import style from "../style/picknumber.module.scss";
import { connect } from "react-redux";
import ERC20TokenABI from "../ERC20token_contact_abi.json";
import * as constants from "../store/constants";
import * as utils from "../utils";
import * as actionCreators from "../store/actionCreators";
import SelectPopup from "../components/SelectPopup";
import Spin from "../components/Spin";
import { message } from "antd";
import { FormattedMessage } from "react-intl";
import Decimal from "decimal.js";
import yinNImg from "../images/yinN.png";
import yangNImg from "../images/yangN.png";
import { blankAddr } from "../store/constants";

class TwoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableNum: this.props.match.params.tableNum,
      nums: [],
      account: "",
      position: "",
      tableData: { bettor: [] },
      pickedNum: [],
      checkboxChecked: 1,
      showSelectPopup: false,
      loading: false,
    };
    this.handleClickNumber = this.handleClickNumber.bind(this);
    this.sendPickedNum = this.sendPickedNum.bind(this);
    this.handlePickPosition = this.handlePickPosition.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.changeCurrentValue = this.changeCurrentValue.bind(this);
    this.changeTable = this.changeTable.bind(this);
  }
  async componentDidMount() {
    await this.getTableData(this.state.tableNum);
    const { currentOption,lang } = this.props;
    const ERC20TokenABIIns = new (utils.web3().eth.Contract)(
      ERC20TokenABI,
      currentOption.token.address
    );
    const accounts = await utils.web3().eth.getAccounts();
    const account = accounts[0];
    this.setState({ account });
    let allowance = await ERC20TokenABIIns.methods
      .allowance(account, constants.contractAddr[localStorage.getItem("mode")])
      .call();
    if (Number(allowance) < Number(currentOption.value)) {
      this.toggleLoading(true);
      try {
        await ERC20TokenABIIns.methods
          .approve(
            constants.contractAddr[localStorage.getItem("mode")],
            new Decimal(currentOption.value)
              .mul(new Decimal(10).pow(5))
              .toFixed()
          ) //100000倍当前选项 token allowance
          .send({ 
            from: account,
            gasPrice : await utils.web3().eth.getGasPrice()
           });
        this.toggleLoading(false);
      } catch (e) {
        let msg= lang=='zh'?'请先授权交易':'Please Authorize Trading First'
        message.info(msg);
        this.toggleLoading(false);
        window.history.back(-1);
      }
    }
  }
  async getTableData(tableNum) {
    const { currentOption } = this.props;
    const tableData = await utils.getGame(
      currentOption.token.address,
      tableNum
    );
    const pickedNum = [];
    if (tableData) {
      for (let i = 0; i < 5; i++) {
        if (tableData.bettor[i] !== constants.blankAddr) {
          pickedNum.push(tableData.betNum1[i]);
          pickedNum.push(tableData.betNum2[i]);
        }
      }
    }
    this.setState({
      tableData,
      pickedNum,
    });
  }
  handleClickNumber(num) {
    const { nums, pickedNum } = this.state;
    if (pickedNum.indexOf(num) > -1) {
      return;
    }
    const ixof = nums.indexOf(num);
    if (ixof === -1) {
      nums.push(num);
    } else {
      nums.splice(ixof, 1);
    }
    this.setState({
      nums,
    });
    if (nums.length === 2) {
      if (nums[0] > nums[1]) {
        [nums[0], nums[1]] = [nums[1], nums[0]];
      }
      this.sendPickedNum();
    }
  }

  handlePickPosition(position, isDisabled, ix) {
    if (isDisabled) return;
    this.setState({
      position,
    });
    this.sendPickedNum(ix);
    // if (this.state.nums.length === 2) {
    //   this.sendPickedNum();
    // }
  }

  handleCheckbox(e) {
    this.setState({
      checkboxChecked: e.target.checked ? 1 : 0,
    });
  }

  toggleSelectPopup(status) {
    this.setState({
      showSelectPopup: status,
    });
  }

  changeCurrentValue(val) {
    if (val === 0) return;
    const { history, setCurrentValue } = this.props;
    setCurrentValue(val);
    history.push("/home");
  }

  toggleLoading(loading) {
    this.setState({
      loading,
    });
  }

  async changeTable() {
    const { currentOption } = this.props;
    const newTable = await utils.getRandomGame(
      currentOption.token.address,
      currentOption.value
    );
    const tableNum = newTable.gameNum;
    if (this.state.tableNum === tableNum) {
      this.setState({
        tableNum: 0,
        pickedNum: [],
        tableData: { bettor: [] },
        position: "",
      });
      this.getTableData(0);
    } else {
      this.setState({ tableNum, pickedNum: [], position: "" });
      this.getTableData(tableNum);
    }
  }

  async sendPickedNum(index) {
    const { currentOption, history, allPostions, lang } = this.props;
    const dayIndex = await utils.getCurrentDayIndex();
    const accounts = await utils.web3().eth.getAccounts();
    const account = accounts[0];
    const state = this.state;
    if (state.position === "") {
      return;
    }
    const params = {
      tokenAddress: currentOption.token.address,
      dayIndex: dayIndex,
      betValue: currentOption.value,
      gameNum: state.tableNum,
      conflictOption: state.checkboxChecked,
      betNum1: index, //yin 0  yang 1
      betNum2: 2,
      position: allPostions.indexOf(state.position),
    };
    this.setState({
      nums: [],
    });
    const that = this;
    this.toggleLoading(true);
    utils
      .contractInstance()
      .methods.fillIn(
        params.tokenAddress,
        params.dayIndex,
        params.betValue,
        params.gameNum,
        params.conflictOption,
        params.betNum1,
        params.betNum2,
        params.position
      )
      .send({ 
        from: account, 
        gas: 1000000,
        gasPrice : await utils.web3().eth.getGasPrice()
       })
      .then(function (res) {
        if (res.status) {
          let sucMsg= lang=='zh'?'投注成功':'Bet Successful'
          message.success({
            content: sucMsg,
            style: {
              marginTop: "40vh",
            },
          });
          history.push("/home");
        }
        that.toggleLoading(false);
      })
      .catch(function (e) {
        let errMsg= lang=='zh'?'投注失败':'Bet Failed'
        message.error(errMsg);
        history.push("/home");
        that.toggleLoading(false);
      });
  }

  render() {
    const { allPostions, currentOption, tokenValueOptions } = this.props;
    const { tableData, position, loading, account } = this.state;
    return (
      <div className={style.pickNumber}>
        {loading || !tableData ? (
          <div className="spin-bg">
            <Spin />
          </div>
        ) : null}
        <div
          onClick={() => {
            this.props.history.push("/home");
          }}
          className={style.bg}
        ></div>
        <div className={style.container}>
          <div className={style.title}>
            <FormattedMessage id="participateTwo" />
            <span onClick={this.changeTable}>
              <FormattedMessage id="change" />
            </span>
          </div>
          <p className={style.subtitle}>
            <FormattedMessage id="infuseAura" />{" "}
            <span>
              {utils.formatValueOption(
                utils.removeDecimal(
                  currentOption.value,
                  currentOption.token.decimal
                ),
                currentOption.token.unitName,
                currentOption.token.unitDecimal
              )}{" "}
              {currentOption.token.name}
            </span>
          </p>
          <p className={style.hint}>
            * <FormattedMessage id="neededForReiki" />
            <span
              onClick={() => {
                this.toggleSelectPopup(true);
              }}
            >
              <FormattedMessage id="switchPool" />
            </span>
          </p>
          <div className={style.positionTwo}>
            {allPostions.map((pos, ix) => {
              return (
                <div
                  key={ix}
                  className={`${style["positionTwo" + pos]} ${
                    style.positionTwoDiv
                  }`}
                  onClick={() => {
                    this.handlePickPosition(
                      pos,
                      tableData && tableData.bettor[ix] !== constants.blankAddr,
                      ix
                    );
                  }}
                >
                  <span
                    className={`${style[pos]} 
                                    ${position === pos ? style.picked : ""}
                                    ${
                                      tableData.bettor[ix] !== blankAddr
                                        ? tableData.bettor[ix] === account
                                          ? style["disabledSelf" + pos]
                                          : style["disabledOther" + pos]
                                        : ""
                                    }`}
                  ></span>
                </div>
              );
            })}
          </div>
          <p className={style.subtitle}>
            <FormattedMessage id="selectWinningYoucher" />
          </p>
          <p className={style.hint}>
            * <FormattedMessage id="siTwo" />
          </p>
          <div style={{ display: "flex", marginTop: 8, marginBottom: 16 }}>
            <p className={style.yinYang}>
              <img src={yinNImg} alt="" className={style.yinNImg} />
              <FormattedMessage id="yinN" />
            </p>
            <p className={style.yinYang} style={{ marginLeft: 30 }}>
              <img
                src={yangNImg}
                alt=""
                className={style.yinNImg}
                style={{ marginRight: 15 }}
              />
              <FormattedMessage id="yangN" />
            </p>
          </div>
        </div>
        {this.state.showSelectPopup && (
          <SelectPopup
            close={() => {
              this.toggleSelectPopup(false);
            }}
            buttonText="去切换"
            options={tokenValueOptions}
            tokenInfo={currentOption.token}
            confirm={this.changeCurrentValue}
          ></SelectPopup>
        )}
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    allPostions: ["yin", "yang"],
    currentOption: state.currentOption,
    tokenValueOptions: state.tokenValueOptions,
    lang: state.lang,
  };
};

const mapDispatch = (dispatch) => {
  return {
    setCurrentValue(val) {
      dispatch(actionCreators.setCurrentValue(val));
    },
  };
};

export default connect(mapState, mapDispatch)(TwoPlayer);
