import * as actionTypes from './actionTypes';

export const passEntry = () => ({
    type: actionTypes.PASS_ENRTY
});

export const setTokenValueOptions = (value) => ({
    type: actionTypes.SET_TOKEN_VALUE_OPTIONS,
    value
});

export const setCurrentValue = (value) => ({
    type: actionTypes.SET_CURRENT_VALUE,
    value
});

export const setCurrentTokenData = (data) => ({
    type: actionTypes.SET_CURRENT_TOKEN_DATA,
    data
});

export const setCurrentLang = (data) => ({
    type: actionTypes.SET_CURRENT_LANG,
    data
});
