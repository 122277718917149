import store from "./store";
import { Provider } from "react-redux";
import "./style/reset.css";
import "./style/antd.scss";
import Index from './pages/Index'

function App() {
  return (
    <Provider store={store}>
      <Index/>
    </Provider>
  );
}

export default App;
