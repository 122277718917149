import style from '../style/invitationDetails.module.scss';
import { useState, useEffect } from 'react';
import {web3} from '../utils';
import { message } from 'antd';
import { serverLink } from '../store/constants';

const typeObj={3:'30天',6:'90天',9:'180天',12:'365天'};
let page=1;
const  InvitationDetails= (props) => {
   
    const [list,setList]=useState([]);
    const [address,setAddress]=useState('');
    const [userData,setUserData]=useState({});
    const [total,setTotal]=useState(0);
    // const [page,setPage]=useState(1);

    const userAddress=async ()=>{
        const accounts = await web3().eth.getAccounts();
        const account = accounts[0];
        return account;
    }
    const lookMore=()=>{
        page=page+1;
        getList(address);
    }
    const getData = async (address) => {
        try {
            const res = await fetch(`${serverLink}/api/player-invite-profit/${address}`).then(
                (res) => res.json()
            );
            if (res.code === 0 && res.data) {
                // res.data.last_player='0x191FD74DFa3cEca216ad8eF497ED83670C0783FB'
                if(res.data.last_player){
                    let st=res.data.last_player;
                    res.data.last_player=st.substring(0,6)+'...'+st.substring(st.length-6)
                }
                setUserData(res.data);
               
            } else {
                message.error(res.msg);
            }
        } catch (e) {
            message.error(e);
        }    
    };
    const getList=async (address)=>{
        try {
            const res = await fetch(`${serverLink}/api/player_invite_assets_regular?page=${page}&playerAddress=${address}`).then(
                (res) => res.json()
            );
            if (res.code === 0 && res.data) {
                res.data.list.forEach((item,index)=>{
                    item.player='...'+(item.player).substring(item.player.length-3)
                })
                setList(list.concat(res.data.list));
                setTotal(res.data.count);
            } else {
                message.error(res.msg);
            }
        } catch (e) {
            message.error(e);
        }   
    }
    
    useEffect(()=>{
        document.title='我的邀请';
        userAddress().then((r)=>{
            setAddress(r);
            getData(r);
            getList(r);
        })
       
    },[props.location.state])
    return(
        <div className={style.all}>
            <div className={style.last}>
                <div>我的上级</div>
                <div>{userData.last_player?userData.last_player:'您还没有上级哦～'}</div>   
            </div>
            <div className={style.inviteData}>
                <div className={style.inviteP}>
                    <div>成功邀请(人)</div>
                    <div>{userData.succ_invitations}</div>
                </div>
                <div className={style.inviteP}>
                    <div>已获得奖励</div>
                    <div>{userData.awarded}</div>
                </div>
            </div>
            <div className={style.detail}>
                <div className={style.detailHeader}>
                    <div>地址</div>
                    <div>锁仓类型</div>
                    <div>数量</div>
                </div>
                {list.length===0?<div className={style.listItem}>邀请人还没有锁仓哦～</div>:''}
                {list.map((item,index)=>{
                        return(
                            <div className={style.listItem}>
                                <div>钱包地址{item.player}</div>
                                <div>锁仓{typeObj[item.type]}</div>
                                <div>{item.amount}</div>
                            </div>  
                            
                        )
                    })}
                {list.length<total?<div className={style.more} onClick={()=>lookMore()}>查看更多</div>:''}
                
            </div>
        </div>
    )
}
export default InvitationDetails;