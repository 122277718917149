import React, { useState, useEffect } from 'react';
import style from '../style/infobox.module.scss';
import SelectPopup from '../components/SelectPopup';
import { FormattedMessage } from 'react-intl';

const formatTotalRewardAmounts = (totalRewardAmounts) => {
    const arr = [];
    for (let k in totalRewardAmounts) {
        arr.push({
            name: k,
            number: totalRewardAmounts[k]
        });
    }
    return arr;
};
const InfoBox = (props) => {
    const { heading, number, isFourColumns, totalRewardAmounts } = props;
    const [showSelectPopup, setShowSelectPopup] = useState(false);
    const [contentArr, setContentArr] = useState([]);
    useEffect(() => {
        if (isFourColumns && Object.keys(totalRewardAmounts).length) {
            setContentArr(formatTotalRewardAmounts(totalRewardAmounts));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalRewardAmounts]);
    if (isFourColumns) {
        return (
            <div className={style.infoBox}>
                <div className={style.listWrapper}>
                    <ul
                        className={style.list}
                        style={{ width: `${108 * contentArr.length}px` }}>
                        {contentArr.length &&
                            contentArr.map((token, ix) => {
                                return (
                                    <li key={ix}>
                                        <p className={style.tokenName}>
                                            {token.name}
                                        </p>
                                        <p className={style.tokenNumber}>
                                            {token.name === 'HJW' && new Date().getMonth()===8 && new Date().getDate()===2 ? token.number-300:token.number}
                                        </p>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
                <button
                    className={style.btn}
                    onClick={() => {
                        setShowSelectPopup(true);
                    }}>
                    <FormattedMessage id='bonusList' />
                </button>
                {showSelectPopup && (
                    <SelectPopup
                        close={() => {
                            setShowSelectPopup(false);
                        }}
                        contentArr={contentArr}
                        title={<FormattedMessage id='bonusPoolPrizes' />}
                        hint={<FormattedMessage id='bonusPoolPrizesHint' />}
                        displayOnly={true}></SelectPopup>
                )}
            </div>
        );
    } else {
        return (
            <div className={style.infoBox}>
                <p className={style.heading}>{heading}</p>
                <p className={style.number}>{number}</p>
            </div>
        );
    }
};

export default InfoBox;
