import React from 'react';
import style from '../style/popup.module.scss';

function Popup(props) {
    const { text, handleClick, buttonText, minHeight, paddingTop } = props;
    return (
        <div
            className={style.popup}
            style={{ minHeight: minHeight, paddingTop: paddingTop }}>
            <div className={style.box}>
                <p >{text}</p>
                <button onClick={handleClick}>{buttonText}</button>
            </div>
        </div>
    );
}

export default Popup;
