import '../style/global.scss';

const Spin = () => {
    return (
        <div className='spin'>
            <span className='spin-dot spin-dot-spin'>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
            </span>
        </div>
    );
};

export default Spin;
