import React, { useState } from 'react';
import style from '../style/popup.module.scss';
import * as utils from '../utils';

function SelectPopup(props) {
    const [selection, setSelection] = useState(null);
    const {
        close,
        buttonText,
        options,
        tokenInfo,
        type,
        displayOnly,
        contentArr,
        title,
        hint
    } = props;
    const confirm = () => {
        if (!selection) return;
        props.confirm(selection);
    };
    if (displayOnly) {
        return (
            <div className={style.selectPopup}>
                <div className={style.bg} onClick={close}></div>
                <div className={style.box}>
                    {title && <p className={style.title}>{title}</p>}
                    <div className={style.optionsContainer}>
                        {contentArr.map((token, ix) => {
                            return (
                                <div
                                    className={`${style.options} ${style.tokenInfo}`}
                                    key={ix}>
                                    <span className={style.tokenName}>
                                        {token.name}
                                    </span>
                                    <span className={style.tokenNumber}>
                                        {token.number}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                    {hint && <p className={style.hint}>{hint}</p>}
                </div>
            </div>
        );
    } else {
        let OptionsText = [];
        if (type === 'tokenOption') {
            options.forEach((v) => {
                OptionsText.push(v.name);
            });
        } else {
            OptionsText = options;
        }
        const handleSelect = (ix) => {
            setSelection(options[ix]);
            Array.prototype.forEach.call(
                document.getElementsByClassName('options'),
                (item) => {
                    item.classList.remove(style.active);
                }
            );
            document.getElementById(`option${ix}`).classList.add(style.active);
        };
        return (
            <div className={style.selectPopup}>
                <div className={style.bg} onClick={close}></div>
                <div className={style.box}>
                    <div className={style.optionsContainer}>
                        {OptionsText.map((option, ix) => {
                            return (
                                <div
                                    className={`${style.options} options`}
                                    key={ix}
                                    onClick={() => {
                                        handleSelect(ix);
                                    }}
                                    id={`option${ix}`}>
                                    {type !== 'tokenOption'
                                        ? utils.removeDecimal(
                                              option,
                                              tokenInfo.decimal
                                          )
                                        : option}{' '}
                                    {type !== 'tokenOption'
                                        ? tokenInfo.name
                                        : null}
                                </div>
                            );
                        })}
                    </div>
                    <button
                        onClick={confirm}
                        className={!selection ? style.disabled : ''}>
                        {buttonText}
                    </button>
                </div>
            </div>
        );
    }
}

export default SelectPopup;
