import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

document.onreadystatechange = function () {
    if (document.readyState === 'complete') {
        let interval = setInterval(function () {
            if (window.ethereum) {
                clearInterval(interval);
                ReactDOM.render(
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>,
                    document.getElementById('root')
                );
            }
        }, 500);
    }
};
