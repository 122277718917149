import { Carousel } from "antd";
import style from "../style/selectMode.module.scss";
import twoPlayerCard from "../images/twoPlayerCard.png";
import fivePlayerCard from "../images/fivePlayerCard.png";
import selectMode1 from "../images/selectMode-1.png";
import selectMode2 from "../images/selectMode-2.png";
import selectMode3 from "../images/selectMode-3.png";
import selectMode4 from "../images/selectMode-4.png";
import { FormattedMessage } from "react-intl";

const SelectMode = (props) => {
  //   const [currentTab, setCurrentTab] = useState(3)
  const imgList = [twoPlayerCard, fivePlayerCard];
  const selectMode = (index) => {
    localStorage.setItem("mode", index);
    props.history.push("/home");
  };

  //   useEffect(() => {
  //     page = 1
  //     getUserAddress().then(r => {
  //       setUserAddress(r)
  //       getData(3, r)
  //     })
  //   }, [])
  return (
    <div className={style.main}>
      <div className={style.content}>
        <img src={selectMode1} alt="" className={style.selectMode1} />
        <div className={style.changeMode}>
          <Carousel className={style.imgCard}>
            {imgList.map((item, index) => (
              <img
                src={item}
                key={item}
                alt=""
                onClick={() => {
                  selectMode(index);
                }}
              />
            ))}
          </Carousel>

          <img src={selectMode2} alt="" className={style.selectMode2} />
          <div className={style.changeModeText}>
            <img src={selectMode3} alt="" />
            <div>
              <FormattedMessage id="switchModes" />
            </div>
          </div>
        </div>
        <img src={selectMode4} alt="" className={style.selectMode4} />
      </div>
    </div>
  );
};

export default SelectMode;
