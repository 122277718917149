import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import style from '../style/profile.module.scss';
import * as utils from '../utils';
import { FormattedMessage } from 'react-intl';
import SelectPopup from '../components/SelectPopup';
import { setCurrentTokenData, setCurrentValue } from '../store/actionCreators';
import { serverLink } from '../store/constants';
import Spin from '../components/Spin';
import { Input } from 'antd';
import { message } from 'antd';
import { rewardBagAddress, V2Url } from '../store/constants';
import { Modal } from 'antd';
let account;
let interval;
let sendBagIdFlag = false;

const getLingdan = async () => {
    try {
        const accounts = await utils.web3().eth.getAccounts();
        account = accounts[0];
        let fetchData = {};
        await fetch(`${serverLink}/api/player-info/${account}`)
            .then(async (res) => res.json())
            .then((data) => {
                if (data.code === 0) {
                    fetchData = data.data;
                } else if (data.code === 1) {
                    message.error('网络错误');
                }
            });
        return fetchData;
    } catch (e) {
        console.log(e);
        message.error('网络错误');
        return {};
    }
};
const callBack = async (hash, toggleLoading) => {
    try {
        let fetchData = {};
        await fetch(`${serverLink}/api/fv2-txhash-back/${hash}`)
            .then(async (res) => res.json())
            .then((data) => {
                if (data.code == 0) {
                    message.success(data.data.msg || "修改成功");
                } else if (data.code == 1 && data.data) {
                    message.error(data.data.msg || "修改失败");
                } else {
                    message.error(data.msg || "修改失败");
                }

                toggleLoading(false)
            });
        return fetchData;
    } catch (e) {
        console.log(e);
        message.error('网络错误');
        return {};
    }
};
const getInfo = async (currentOption, dayIx) => {
    const token = currentOption.token;
    const todayInfo = await utils.getMySeasonInfo(token.address, dayIx);
    // const myRank = await utils.getMyRank(dayIx);
    const yesterdayInfo = await utils.getMySeasonInfo(token.address, dayIx - 1);
    return {
        score: utils.removeDecimal(todayInfo.score, token.decimal, 1),
        yesterdayScore: utils.removeDecimal(
            yesterdayInfo.score,
            token.decimal,
            1
        ),
        totalGameNum: todayInfo.totalGameNum,
        myRank: 0,
        rewards: utils.removeDecimal(yesterdayInfo.rewards, token.decimal, 3)
    };
};

const reissuing = async (account, myGold, toggleLoading, lang) => {
    toggleLoading(true);
    let errMsg = lang === 'zh' ? '网络错误' : 'Network errors';
    try {
        let result;
        await fetch(`${serverLink}/api/reward-bag/${account}/day-index/18849`)
            .then(async (res) => res.json())
            .then(async (data) => {
                if (data.code === 0) {
                    const _data = data.data;
                    console.log(_data);
                    if (!_data) {
                        clearInterval(interval);
                        toggleLoading(false);
                        return;
                    }
                    if (!sendBagIdFlag && !_data?.Status) {
                        sendBagIdFlag = true;
                        let sendBagRes;
                        try {
                            sendBagRes = await sendBagId(_data.ID, account);
                        } catch {
                            let injectedMsg =
                                lang === 'zh'
                                    ? '授权失败'
                                    : 'Authorization failed';
                            message.info(injectedMsg);
                            clearInterval(interval);
                            sendBagIdFlag = false;
                            toggleLoading(false);
                        }
                        if (!sendBagRes) {
                            return;
                        } else {
                            interval = setInterval(() => {
                                reissuing(account, myGold, toggleLoading, lang);
                            }, 3000);
                        }
                    }
                    if (_data?.Status) {
                        result = {
                            Status: _data.Status
                        };
                        clearInterval(interval);
                        let successMsg =
                            lang === 'zh' ? '补发成功' : 'Retroactive success';
                        message.info(successMsg);
                        toggleLoading(false);
                    }
                } else {
                    message.error(errMsg);
                    clearInterval(interval);
                    toggleLoading(false);
                }
            });
        return result;
    } catch (e) {
        console.log(e);
        message.error(errMsg);
        toggleLoading(false);
        return null;
    }
};

const sendBagId = async (id, account) => {
    var stringtoHex = function (str) {
        var val = '';
        for (var i = 0; i < str.length; i++) {
            if (val === '') val = str.charCodeAt(i).toString(16);
            else val += str.charCodeAt(i).toString(16);
        }
        return val;
    };

    const res = await new Promise((resolve, reject) => {
        utils.web3().eth.sendTransaction(
            {
                from: account,
                to: rewardBagAddress,
                amount: 0,
                data: stringtoHex(`openBag:${id}`)
            },
            (err, res) => {
                if (!err) {
                    console.log(res);
                    resolve(true);
                } else {
                    console.log(err);
                    reject(false);
                }
            }
        );
    });
    return res;
};

let dayIx;

const Profile = (props) => {
    const [showSelectPopup, setShowSelectPopup] = useState(false);
    const [currentTokenName, setCurrentTokenName] = useState(false);

    const [myInfo, setMyInfo] = useState({
        score: 0,
        yesterdayScore: 0,
        totalGameNum: 0,
        myRank: 0,
        rewards: 0,
        Nickname: ''
    });
    const [name, setName] = useState('');
    const [isModalVisible, setVisible] = useState('');
    const openModal = () => {
        setVisible(true)
    }
    const handleCancel = () => {
        setVisible(false)
        setName(lingdanInfo.Nickname)
    }

    const [lingdanInfo, setLingdanInfo] = useState({
        Point: 0
    });

    const [loading, setLoading] = useState(false);

    const { currentOption, tokenOptions, lang } = useSelector((state) => state);
    const dispatch = useDispatch();

    const init = async () => {
        dayIx = await utils.getCurrentDayIndex();
        const info = await getInfo(currentOption, dayIx);
        setMyInfo(info);
        console.log(info)

    };
    let actionContract = utils.actionContract();
    const nameChange = value => {
        console.log(value.target.value)
        setName(value.target.value);

        // actionContract.methods
        //     .requestModifyNickname(value.target.value)
        //     .send({ from: account })
        //     .then((res) => {
        //     })

    };
    const submitName = () => {
        toggleLoading(true);
        if (name) {
            actionContract.methods
                .requestModifyNickname(name)
                .send({ from: account })
                .then((res) => {
                    setTimeout((
                    ) => { callBack(res.transactionHash, toggleLoading) }, 10000)

                    setVisible(false)
                })
        } else {
            message.error('名字不能为空');
        }
    };

    const changeTokenData = (data) => {
        dispatch(setCurrentTokenData(data));
        dispatch(setCurrentValue('0'));
        setCurrentTokenName(data.name);
        setShowSelectPopup(false);
    };

    const toggleLoading = (status) => {
        setLoading(status);
    };

    useEffect(() => {
        const _init = async () => {
            init();
            const lingdanInfo = await getLingdan();
            setLingdanInfo(lingdanInfo);
            setName(lingdanInfo.Nickname)
        };
        _init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTokenName]);

    return loading ? (
        <div className='spin-bg'>
            <Spin />
        </div>
    ) : (
        <div className={style.profile}>
            <div className={style.header}>
                <div
                    onClick={() => {
                        window.history.back()
                    }}
                    className={style.back}></div>
                <FormattedMessage id='mine' />
            </div>
            <div className={style.container}>
                <div className={style.info}>
                    <span className={style.hint}>
                        * <FormattedMessage id='onlyShow' />
                    </span>
                    <span
                        className={`${style.status} ${myInfo.myRank === 0 ? '' : style.active
                            }`}>
                        <span>
                            {myInfo.myRank === 0 ? (
                                <FormattedMessage id='notOnTheList' />
                            ) : (
                                myInfo.myRank
                            )}
                        </span>
                    </span>
                </div>
                <div className={style.item}>
                    <span className={`${style.title}`}>
                        <FormattedMessage id='myName' />
                    </span>
                    <span className={style.content}>
                        <Modal title="修改侠名" visible={isModalVisible} cancelText='取消修改' okText='修改' onOk={submitName} onCancel={handleCancel}>
                            <Input placeholder="请输入侠名" value={name} onChange={
                                nameChange
                            } />
                        </Modal>
                        <span className={`${style.submitName}`} onClick={() => {
                            openModal()
                        }}>{name ? name : '请输入侠名'}</span>
                        {/* <Input placeholder="请输入侠名" value={name} onChange={
                            nameChange
                        } />
                        <span className={`${style.submitName}`} onClick={() => {
                            submitName()
                        }}>确认修改</span> */}
                    </span>
                </div>
                {/* <div className={style.item}>
                    <span className={`${style.title}`}>
                        <FormattedMessage id='myLevel' />
                    </span>
                    <span className={style.content}>
                        <span className={style.levelProcess}>
                            <p className={style.flex} >
                                <span className={style.pink}>level5</span ><span className={`${style.grey} ${style.size_12}`}>level6</span>
                            </p>
                            <div className={style.processLay}>
                                <div className={style.process}></div>
                            </div>
                        </span>
                    </span>
                </div> */}
                <div
                    className={style.item}
                    onClick={() => {
                        setShowSelectPopup(true);
                    }}>
                    <span className={style.title}>
                        <FormattedMessage id='currency' />
                    </span>
                    <span className={style.arrow}></span>
                    <span className={style.content}>
                        {currentOption.token.name}
                    </span>
                </div>
                {/* <div className={style.item}>
                    <span className={`${style.title} ${style.gold}`}>
                        <FormattedMessage id='myElixir' />
                    </span>
                    <span className={style.content}>{myInfo.score}</span>
                </div> */}
                <div
                    className={style.item}
                    onClick={() => {
                        props.history.push({
                            pathname: '/lingdanDetails'
                        });
                    }}>
                    <span className={`${style.title} ${style.lingDan}`}>
                        <FormattedMessage id='myLingDan' />
                    </span>
                    <span className={style.arrow}></span>
                    <span className={style.content}>
                        {utils.removeDecimal(lingdanInfo.Point, 0, 1)}
                    </span>
                </div>
                <div
                    className={style.item}
                    onClick={() => {
                        props.history.push({
                            pathname: '/shengwuDetails'
                        });
                    }}>
                    <span className={`${style.title} ${style.shengwu}`}>
                        <FormattedMessage id='myShengwu' />
                    </span>
                    <span className={style.arrow}></span>
                    {/* <span className={style.content}>
                        {removeDecimal(lingdanInfo.Point, 0, 1)}
                    </span> */}
                </div>
                <div className={style.item}>
                    <span className={style.title}>
                        <FormattedMessage id='bonus' />
                    </span>
                    <span className={style.content}>
                        {myInfo.rewards} {currentOption.token.name}
                    </span>
                </div>
                <div
                    className={style.item}
                    onClick={() => {
                        props.history.push('/tables/finished');
                    }}>
                    <span className={style.title}>
                        <FormattedMessage id='todayData' />
                    </span>
                    <span className={style.arrow}></span>
                    <span className={style.content}>{myInfo.totalGameNum}</span>
                </div>
                {dayIx === '18850' && myInfo.yesterdayScore >= 188 ? (
                    <div
                        className={style.item}
                        onClick={() => {
                            reissuing(
                                account,
                                myInfo.yesterdayScore,
                                toggleLoading,
                                lang
                            );
                        }}>
                        <span className={style.title}>
                            <FormattedMessage id='reissuing' />
                        </span>
                        <span className={style.arrow}></span>
                    </div>
                ) : null}
                <div
                    className={style.item}
                    onClick={() => {
                        window.location.href = V2Url + 'unionstatus?type=v1';
                    }}>
                    <span className={style.title}>
                        <FormattedMessage id='myUnion' />
                    </span>
                    <span className={style.arrow}></span>
                </div>
                <p className={style.activity}>
                    <FormattedMessage id='activity' />
                </p>
                <div className={style.item}>
                    <span className={style.title}>
                        <FormattedMessage id='activity_total' />
                    </span>
                    <span className={style.content}>{lingdanInfo.Active}</span>
                </div>
                <div className={style.item}>
                    <span className={style.title}>
                        <FormattedMessage id='activity_day' />
                    </span>
                    <span className={style.content}>{lingdanInfo.ActiveDay}</span>
                </div>
            </div>
            {showSelectPopup && (
                <SelectPopup
                    close={() => {
                        changeTokenData(false);
                    }}
                    buttonText={<FormattedMessage id='switch' />}
                    options={tokenOptions}
                    tokenInfo={currentOption.token}
                    confirm={changeTokenData}
                    type='tokenOption'></SelectPopup>
            )}
        </div>
    );
};

export default Profile;
