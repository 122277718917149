import style from '../style/jianghuOrder.module.scss';
import Logo from '../images/jianghu_logo.png';
import Home from '../images/jianghu_home.png';
import taskImg from '../images/jianghu_task.png';
import { useState, useEffect,useRef } from 'react';
import { Progress,message } from 'antd';
import copy from "copy-to-clipboard";  
import { serverLink } from '../store/constants';
import Spin from '../components/Spin';
import {web3} from '../utils';
// import { FormattedMessage } from 'react-intl';

const arrTab=['推广任务','日常任务','成就任务'];
const copyAddress='https://fivexw.com/#';
const JianghuOrder = (props) => {

    const [currentTab, setCurrentTab] = useState(0);
    const [taskList,setTaskList]=useState([]);
    const [receiveFlag,setReceiveFlag]=useState(false);
    const [countdown,setCountdown]=useState(2);
    const [inviteFriendFlag,setInviteFriendFlag]=useState(false);
    const [userData,setUserData]=useState({});
    const [address,setAddress]=useState('');
    const [loading,setLoading]=useState(false);
    const time=useRef(null)

    const userAddress=async ()=>{
        await window.ethereum.enable();
        const accounts = await web3().eth.getAccounts();
        const account = accounts[0];
        return account;
    }
   const goHome=()=>{
    window.location.href = copyAddress;
   }
    const clickTab=(index)=>{
        setCurrentTab(index);
        getTask(index+1,address); 
    }
    const lookDetail=()=>{
        props.history.push({ pathname: './invitationDetails' });
    }
    //任务操作按钮
    const receive=(type,url,id)=>{
        if(type===2){
            goFinish(url,id);
        }else if(type===3){
            return;
        }else{
            getReceive(id);
        }  
    }
    const receiveAll=()=>{
        let ids=[];
        taskList.forEach((item)=>{
            if(item.is_receive===1){
                ids.push(item.id);
            }
        })
        if(ids.length===0){
            message.warning('还没有要领取的奖励哦！')
        }else{
            getReceiveAll(ids.toString());
        }
        
    }
    const goFinish = async (url,id) => {
        try {
            const res = await fetch(`${serverLink}/api/player-invite-task-succ/${id}/${address}`).then(
                (res) => res.json()
            );
            if (res.code === 0 ) {
                getTask(currentTab+1,address);
                if(currentTab===0){
                    setTimeout(()=>{inviteFriendFun();},10);
                }else{
                    if(url){
                        window.location.href=url;
                    }
                }   
            } else {
                message.error(res.msg);
            }
        } catch (e) {
            message.error(e);
        }    
    };
    //倒计时
    const countdownFun=()=>{
        setCountdown(2);
        time.current=setInterval(()=>{
            setCountdown((countdown)=>countdown-1);
        },1000);
    }
    //邀请好友
    const inviteFriendFun=()=>{
        copy(`${copyAddress}/?paddr=${address}`); 
        setInviteFriendFlag(true);
        countdownFun();
    }

    const getData = async (address) => {
        try {
            const res = await fetch(`${serverLink}/api/player-invite-profit/${address}`).then(
                (res) => res.json()
            );
            if (res.code === 0 && res.data) {
                // if(res.data.distributed_profit>10000){
                //     res.data.distributed_profit=(res.data.distributed_profit / 10000).toFixed(2)+'万'
                // }
               
                setUserData(res.data);
               
            } else {
                message.error(res.msg);
            }
        } catch (e) {
            message.error(e);
        }    
    };
    const getTask = async (type,address) => {
        try {
            const res = await fetch(`${serverLink}/api/player-invite-task/${type}/${address}`).then(
                (res) => res.json()    
            );
            if (res.code === 0 && res.data) {
                setTaskList(res.data);  
            } else {
                message.error(res.msg);
            }
        } catch (e) {
            message.error(e);
        }  
           
    };
    const getReceive = async (id) => {
        setLoading(true);
        try {
            const res = await fetch(`${serverLink}/api/task/${id}/claim`,{method: 'POST'}).then(
                (res) => res.json()
            );
            if (res.code === 0) {
                setLoading(false);
                setReceiveFlag(true);
                getTask(currentTab+1,address);
                getData(address);
                countdownFun();
            } else {
                message.error(res.msg);
            }
        } catch (e) {
            message.error(e);
        }    
    };
    const getReceiveAll = async (ids) => {
        setLoading(true);
        try {
            const res = await fetch(`${serverLink}/api/task/batch-claim/${ids}`,{method: 'POST'}).then(
                (res) => res.json()
            );
            if (res.code === 0) {
                setLoading(false);
                setReceiveFlag(true);
                getTask(currentTab+1,address);
                getData(address);
                countdownFun();
            } else {
                message.error(res.msg);
            }
        } catch (e) {
            message.error(e);
        }    
    };
    
   useEffect(()=>{
    if(countdown<0){
        setReceiveFlag(false);
        setInviteFriendFlag(false);
        clearInterval(time.current);
       
    }
   },[countdown])
    useEffect(()=>{
        userAddress().then((r)=>{
            setAddress(r);
            getData(r);
            getTask(1,r)
        })
        document.title='悬赏令';
    },[])
    return (
        
        <div className={style.all}>
            {loading?(<div className='spin-bg'><Spin /></div>):null}
            <div className={style.header}>
                <img src={Logo} alt='' className={style.logo}></img>
                <img src={Home} alt='' className={style.home} onClick={()=>goHome()}></img>
            </div>
            <div className={style.black}>
                <div className={style.income}>
                    <div className={style.promote}>悬赏令奖池</div>
                    <div className={style.promoteIV}>{userData.today_extension_profit}</div>
                    <div className={style.distribute}>
                        <div className={style.distributeI}>
                            <div>已分发收益</div>
                            <div>{userData.distributed_profit}</div>
                        </div>
                        <div className={style.distributeI}>
                            <div>待分发收益</div>
                            <div>{userData.distributeing_profit}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.main}>
                <div className={style.blank1}></div>
                <div className={style.tab}>
                    {arrTab.map((item,index)=>{
                        return(
                        <div onClick={()=>clickTab(index)} 
                            className={currentTab===index?style.selected:style.noSelected} 
                            key={index}>
                                {item}
                        </div>)
                    })}
                </div>
                <div className={style.task}>
                    <div className={style.receiveAll} onClick={()=>receiveAll()}>一键领取</div>
                    {taskList.map((item,index)=>{
                        return(
                            <div className={style.taskItem} key={index}>
                                <div className={style.taskDetail}>
                                    <img src={taskImg} alt=''></img>
                                    <div className={style.taskReward}>
                                        <div>{item.task_content}</div>
                                        <div>{item.reward_content}</div>
                                    </div>
                                </div>
                                {item.is_receive===1?<div className={style.receive} onClick={()=>receive(1,'',item.id)}>领取</div>:''}
                                {item.is_receive===3?<div className={style.received} onClick={()=>receive(3)}>已领取</div>:''}
                                {item.is_receive===2?<div className={style.receive} onClick={()=>receive(2,item.task_url,item.id)}>去完成</div>:''}
                                {item.is_receive===4?<div className={style.taskProgress} >
                                     <div>进行中<span className={style.progressData}>{item.not_num}</span><span>/{item.total}</span></div>
                                    <Progress percent={(item.not_num/item.total)*100} showInfo={false} strokeColor='#EFB549' trailColor='#65483C' size="small"></Progress>
                                </div>:''}    
                            </div>
                        )
                    })}
                </div>
                <div className={style.blank2}></div>
                <div className={style.invite}>
                    <div className={style.myInvite}><div>我的邀请</div></div>
                    <div className={style.inviteDetail}>
                        <div className={style.receiveAll} onClick={()=>lookDetail()}>查看明细</div>
                        <div className={style.inviteData}>
                            <div className={style.inviteP}>
                                <div>成功邀请(人)</div>
                                <div>{userData.succ_invitations}</div>
                            </div>
                            <div className={style.inviteP}>
                                <div>已获得奖励</div>
                                <div>{userData.awarded}</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className={style.blank3}></div>
                <div className={style.inviteFriend}>
                    <div onClick={()=>inviteFriendFun()}>邀请好友</div>
                </div>
            </div>
            <div className={style.receiveModal} style={{display:receiveFlag?'flex':'none'}}>
                <div className={style.receiveContent}>
                    <div>恭喜您</div>
                    <div>成功领取奖励</div>
                    <div>{countdown}秒后自动关闭</div>
                </div>
            </div>
            <div className={style.receiveModal} style={{display:inviteFriendFlag?'flex':'none'}}>
                <div className={style.receiveContent}>
                    <div>成功复制链接</div>
                    <div>邀请链接地址：{copyAddress}/?paddr={address}</div>
                    <div>{countdown}秒后自动关闭</div>
                </div>
            </div>
        </div>
        
    );
};

export default JianghuOrder;