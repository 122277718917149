import React from 'react';
import style from '../style/rules.module.scss';
import { FormattedMessage } from 'react-intl';
import pie1Img from '../images/pie1.png';
import pie2Img from '../images/pie2.png';
import pie3Img from '../images/pie3.png';
import pie1ImgEn from '../images/pie1_en.png';
import pie2ImgEn from '../images/pie2_en.png';
import pie3ImgEn from '../images/pie3_en.png';
import { useSelector } from 'react-redux';

function Rules(props) {
    const lang = useSelector((state) => state.lang);
    return (
        <div className={style.rules}>
            <div onClick={props.close} className={style.bg}></div>
            <div className={style.container}>
                <p className={style.title}>
                    <FormattedMessage id='gameRules' />
                </p>
                <p className={style.subtitle}>
                    <FormattedMessage id='revelation' />：
                </p>
                <p>
                    <FormattedMessage id='revelationTxt' />
                </p>
                <p className={style.subtitle}>
                    <FormattedMessage id='wayOfParticipation' />：
                </p>
                <p>
                    <FormattedMessage id='wayOfParticipationTxt1' />
                    <span>
                        <FormattedMessage id='wayOfParticipationTxt2' />
                    </span>
                    <FormattedMessage id='wayOfParticipationTxt3' />
                </p>
                {lang === 'en' ? (
                    <img src={pie1ImgEn} alt=''></img>
                ) : (
                    <img src={pie1Img} alt=''></img>
                )}
                <p className={style.subtitle}>
                    <FormattedMessage id='decrypt' />：
                </p>
                <p>
                    <FormattedMessage id='decryptTxt1' />
                </p>
                {lang === 'en' ? (
                    <img src={pie2ImgEn} alt=''></img>
                ) : (
                    <img src={pie2Img} alt=''></img>
                )}
                <p className={style.subtitle}>
                    <FormattedMessage id='pillFurnaceRewardRules' />：
                </p>
                <p>
                    <FormattedMessage id='pillFurnaceRewardRulesTxt1' />
                    <span>
                        <FormattedMessage id='pillFurnaceRewardRulesTxt2' />
                    </span>
                    <br />
                    <FormattedMessage id='pillFurnaceRewardRulesTxt3' />
                    <span>
                        <FormattedMessage id='pillFurnaceRewardRulesTxt4' />
                    </span>
                    <br />
                    <span>
                        <FormattedMessage id='pillFurnaceRewardRulesTxt5' />
                    </span>
                    <br />
                    <FormattedMessage id='pillFurnaceRewardRulesTxt6' />
                    <span>
                        <FormattedMessage id='pillFurnaceRewardRulesTxt7' />
                    </span>
                </p>
                {lang === 'en' ? (
                    <img src={pie3ImgEn} alt=''></img>
                ) : (
                    <img src={pie3Img} alt=''></img>
                )}
                <p className={style.hint}>
                    <FormattedMessage id='rulesHintTxt1' />
                    <br />
                    <FormattedMessage id='rulesHintTxt2' />
                    <br />
                    <FormattedMessage id='rulesHintTxt3' />
                </p>
            </div>
        </div>
    );
}

export default Rules;
