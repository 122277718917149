import style from '../style/menu.module.scss';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { V2Url } from '../store/constants';

const Menu = (props) => {
    const [showDropdown, setShowDropdown] = useState(false);
    return (
        <div className={style.menu}>
            <div
                className={style.icon}
                onClick={() => {
                    setShowDropdown(!showDropdown);
                }}></div>
            {showDropdown && (
                <ul className={style.dropdown}>
                    <li
                        className={style.active}
                        onClick={() => {
                            window.location.href = V2Url;
                        }}>
                        <FormattedMessage id='homepage' />
                    </li>
                    <li
                        onClick={() => {
                            props.history.push('/market');
                        }}>
                        <FormattedMessage id='market' />
                    </li>
                    <li
                        onClick={() => {
                            window.location.href =
                                'https://www.certik.org/projects/aura';
                        }}>
                        <FormattedMessage id='reportLink' />
                    </li>
                    <li
                        onClick={() => {
                            props.history.push('/profile');
                        }}>
                        <FormattedMessage id='mine' />
                    </li>
                </ul>
            )}
        </div>
    );
};

export default Menu;
