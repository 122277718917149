import * as actionTypes from './actionTypes';
import { tokenOptions } from './constants';

console.log('1', tokenOptions);
const defaultState = {
    tokenOptions: tokenOptions,
    tokenValueOptions: [],
    currentOption: {
        token: tokenOptions[0],
        value: '0'
    },
    lang: 'zh'
};
sessionStorage.setItem('betValue', defaultState.currentOption.token.address);

const reducer = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case actionTypes.SET_TOKEN_VALUE_OPTIONS:
            newState.tokenValueOptions = action.value;
            newState.currentOption.value = action.value[0];
            return newState;
        case actionTypes.SET_CURRENT_VALUE:
            newState.currentOption.value = action.value.toString();
            return newState;
        case actionTypes.SET_CURRENT_TOKEN_DATA:
            newState.currentOption.token = action.data;
            sessionStorage.setItem('betValue', action.data.address);
            return newState;
        case actionTypes.SET_CURRENT_LANG:
            newState.lang = action.data;
            localStorage.setItem('lang', action.data);
            return newState;
        default:
            return state;
    }
};

export default reducer;
